import React from 'react';
import { BrowserRouter,Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import Body from './Body.js';
import About from './About.js';
import Service from './Service.js';
import SeoPage from './SeoPage.js';
import SemPage from './SemPage.js';
import SmoPage from './SmoPage.js';
import SmmPage from './SmmPage.js';
import Key from './Key.js';
import Ppc from './Ppc.js';
import Linkk from './Linkk.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <BrowserRouter>
    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/body" element={<Body />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/top-global-seo-services-madurai" element={<SeoPage />} />
        <Route path="/leading-sem-services-dindigul" element={<SemPage />} />
        <Route path="/SmoPage" element={<SmoPage />} />
        <Route path="/SmmPage" element={<SmmPage />} />
        <Route path="/Key" element={<Key />} />
        <Route path="/Ppc" element={<Ppc />} />
        <Route path="/Linkk" element={<Linkk />} />
      </Routes>
    
    </BrowserRouter>
  );
}

export default App;