import React from 'react';
// import myImage from './assets/myImage.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Body.css';
import Pan from './assets/Pan.jpg';
import Meme from './assets/Meme.jpg';
import Ben from './assets/Ben.png';
import Gen from './assets/Gen.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';




function Body() {
  return (
        
    <><Navbar expand="lg" className="bg-body-tertiary">
          <Container>
              <Navbar.Brand href="#home">WELcome</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                      <Nav.Link href="home">Home</Nav.Link>
                      <Nav.Link href="sembatti-digital-marketing-courses">Service</Nav.Link>
                      <Nav.Link href="Body">Blog</Nav.Link>
                      <Nav.Link href="About">About</Nav.Link>

                  </Nav>
              </Navbar.Collapse>
          </Container>
      </Navbar>
      
      <div>
        

              <section class="blog-area pt-5 pb-5">
                  <div class="container">
                      <div class="row">
                          <div class="col-xl-12 text-center pb-3">
                              <div class="section-title service-title">
                                  <h2>Latest Blog</h2>
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pulvinar etiam non quam lacus suspendisse faucibus interdum posuere lorem.</p>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                              <div class="blog-wrapper home-blog-wrapper white-bg">
                                  <div class="blog-thumb">
                                      <a href="Key">
                                          <img src={Pan} alt="sp" />
                                      </a>
                                  </div>
                                  <div class="meta-info">
                                      <ul>
                                          <li class="posts-time">August 26, 2024</li>
                                      </ul>
                                  </div>
                                  <div class="blog-content home-blog">
                                      <h2 class="blog-title">
                                          <a href="Key">Keyword Research</a>
                                      </h2>
                                      <p><b>Top Free Keyword Research Tools for 2024</b><br></br>Keyword research is a cornerstone of successful SEO and content marketing strategies. In 2024, businesses and content creators can leverage several powerful free tools to identify the best keywords for their needs. Here’s a look at some of the top free keyword research tools you can use this year,boost your online presence and your keyword strategy.


                                      </p>
                                  </div>
                                  <div class="link-box home-blog-link">
                                      <a href="Key">Read More</a>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                              <div class="blog-wrapper home-blog-wrapper white-bg">
                                  <div class="blog-thumb">
                                      <a href="Ppc">
                                          <img src={Meme} alt="" />
                                      </a>
                                  </div>
                                  <div class="meta-info">
                                      <ul>
                                          <li class="posts-time">July 08, 2024</li>
                                      </ul>
                                  </div>
                                  <div class="blog-content home-blog">
                                      <h2 class="blog-title">
                                          <a href="Ppc">Paid Per Click</a>
                                      </h2>
                                      <p> <b>Step-by-Step Guide to PPC Champion for Beginners</b><br></br>Pay-per-click (PPC) advertising is a powerful tool for driving traffic and generating leads, but it can be overwhelming for beginners. If you’re new to PPC and looking to become a champion in this field, this step-by-step guide will walk you through the essentials to get you started on the right foot.

                                      </p>
                                  </div>
                                  <div class="link-box home-blog-link">
                                      <a href="Ppc">Read More</a>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                              <div class="blog-wrapper home-blog-wrapper white-bg">
                                  <div class="blog-thumb">
                                      <a href="Linkk">
                                          <img src={Ben} alt="sp" />
                                      </a>
                                  </div>
                                  <div class="meta-info">
                                      <ul>
                                          <li class="posts-time">June 10, 2024</li>
                                      </ul>
                                  </div>
                                  <div class="blog-content home-blog">
                                      <h2 class="blog-title">
                                          <a href="Linkk">Link Building</a>
                                      </h2>
                                      <p><b>What Are the Benefits of Link Building for SEO?</b><br></br>Link building is a key strategy in Search Engine Optimization (SEO).Getting other websites to link back to yours is what it involves.. These links, known as backlinks, play an important role in how search engines view and rank your content. By understanding the benefits of link building, you can make it a priority in your SEO strategy.

                                      </p>
                                  </div>
                                  <div class="link-box home-blog-link">
                                      <a href="Linkk">Read More</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
          </div></>
  );
}

export default Body;