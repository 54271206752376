import React from 'react';
import './Service.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import Container from 'react-bootstrap/Container';
import { Carousel, Card, Container, Row, Col } from 'react-bootstrap';
import Pan from './assets/Pan.jpg';
import Meme from './assets/Meme.jpg';
import Seo from './assets/Seo.jpg';
import Sem from './assets/Sem.jpg';


const ServicePage = () => {
  return (
    <><Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">WELcome</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="home">Home</Nav.Link>
            <Nav.Link href="sembatti-digital-marketing-courses">Service</Nav.Link>
            <Nav.Link href="Body">Blog</Nav.Link>
            <Nav.Link href="About">About</Nav.Link>

          </Nav> 
        </Navbar.Collapse>
      </Container>
    </Navbar><div className="App">
        {/* Navbar */}
        <nav className="navbar">
          <h1>Our Services</h1>
          <ul className="nav-links">
            <li><a href="top-global-seo-services-madurai">SEO</a></li>
            <li><a href="leading-sem-services-dindigul">SEM</a></li>
            <li><a href="SmoPage">SMO</a></li>
            <li><a href="SmmPage">SMM</a></li>
          </ul>
        </nav>

        {/* Services */}
        <Container className="my-5">
        <Row>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src={Pan}/>
              <Card.Body>
                <Card.Title>SEO</Card.Title>
                <Card.Text>
                Search Engine Optimization to improve your website's visibility in search engine results
                </Card.Text>
                <a href="top-global-seo-services-madurai" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src={Meme} />
              <Card.Body>
                <Card.Title>SEM</Card.Title>
                <Card.Text>
                Search Engine Marketing to promote your website through paid advertisements on search engines.
                </Card.Text>
                <a href="leading-sem-services-dindigul" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src={Seo} />
              <Card.Body>
                <Card.Title>SMO</Card.Title>
                <Card.Text>
                Social Media Optimization to enhance your brand's presence on social media platforms.
                </Card.Text>
                <a href="SmoPage" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-4">
              <Card.Img variant="top" src={Sem} />
              <Card.Body>
                <Card.Title>SMM</Card.Title>
                <Card.Text>
                Social Media Marketing to advertise your brand or products on social media channels.
                </Card.Text>
                <a href="SmmPage" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      </div></>
  );
};

export default ServicePage;