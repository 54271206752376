import './About.css';
import About2 from './assets/About2.jpg';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
function About() {
  return(
    <><Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">WELcome</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="home">Home</Nav.Link>
            <Nav.Link href="sembatti-digital-marketing-courses">Service</Nav.Link>
            <Nav.Link href="Body">Blog</Nav.Link>
            <Nav.Link href="About">About</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar><div class="static-slider4">
        <div class="container">
          {/* <!-- Row  --> */}
          <div class="row">
            <div class="col-md-5 img-anim">
              <img src={About2} alt="wrapkit" class="img-fluid" />
            </div>
            {/* <!-- Column --> */}
            <div class="col-md-6 ml-auto align-self-center mt-3 mt-md-0">
              <h1 class="title">I’m Sivaprasad <b class="font-weight-bold">Digital Marketing Executive <span class="text-info-gradiant typewrite" data-period="2000" data-type='[ "SEO Expert", "HTML Expert", "CSS3 Expert"]'></span></b></h1>
              <h1 class="title"> <b class="font-weight-bold">Designer,Developer,SEO<span class="text-info-gradiant typewrite" data-period="2000" data-type='[ "SEO Expert", "HTML Expert", "CSS3 Expert"]'></span></b></h1>

              <a class="btn btn-outline-info btn-md mt-3" href=""><span>Checkout My Work</span></a>
            </div>
            {/* <!-- Column --> */}

          </div>
        </div>
        <div class="bg-info-gradiant">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 ml-auto info-box">
                <div class="d-flex">
                  <div class="display-5 text-white mr-3"><i class="font-weight-bold icon-control-play"></i></div>
                  <div class="align-self-center">
                    <h4 class="font-weight-light mb-0"><a href="#" data-toggle="modal" data-target="#static-slide3" class="text-white"><b class="font-weight-bold">10 Marketing Tips for your Project </b><br />05:30 By Angelina Flintoff</a></h4>
                  </div>
                  <div class="modal fade" id="static-slide3">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h6 class="modal-title"></h6>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div class="modal-body" id="yt-player">
                          <iframe width="100%" height="315" src="" frameborder="0" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></>
)}
export default About;