import React, { useState } from 'react';
import './SmoPage.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Row, Col, Button, Form } from 'react-bootstrap';

const SmoPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Here you can add functionality to send the form data to your server or API.
    alert('Thank you for contacting us!');
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <><Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">WELcome</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="home">Home</Nav.Link>
            <Nav.Link href="sembatti-digital-marketing-courses">Service</Nav.Link>
            <Nav.Link href="Body">Blog</Nav.Link>
            <Nav.Link href="About">About</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar><div className="seo-page">
    <nav className="navbar">
          <h1>Our Services</h1>
          <ul className="nav-links">
            <li><a href="top-global-seo-services-madurai">SEO</a></li>
            <li><a href="leading-sem-services-dindigul">SEM</a></li>
            <li><a href="SmoPage">SMO</a></li>
            <li><a href="SmmPage">SMM</a></li>
          </ul>
        </nav>
    <Container>
      <header className="my-5">
        <h1>Amplify Your Brand with Expert Social Media Optimization</h1>
        <p>Engage with Your Audience and Grow Your Online Presence</p>
      </header>

      <section className="my-5">
        <h2>Why Choose Our SMO Services?</h2>
        <Row>
          <Col md={4}>
            <h3>Tailored Social Strategies</h3>
            <p>We create social media strategies that align with your brand and resonate with your audience.</p>
          </Col>
          <Col md={4}>
            <h3>Creative Content</h3>
            <p>Our team produces engaging content that drives conversation and encourages sharing.</p>
          </Col>
          <Col md={4}>
            <h3>Community Building</h3>
            <p>We focus on building and nurturing a strong, active community around your brand.</p>
          </Col>
        </Row>
      </section>

      <section className="my-5">
        <h2>Our SMO Services</h2>
        <Row>
          <Col md={6}>
            <h3>Profile Optimization</h3>
            <p>Enhance your social profiles with optimized bios, images, and keywords for consistent branding.</p>
          </Col>
          <Col md={6}>
            <h3>Content Creation & Curation</h3>
            <p>Create and curate content that reflects your brand’s personality and engages your audience.</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h3>Social Media Campaigns</h3>
            <p>Launch targeted campaigns to promote your products, events, or special offers.</p>
          </Col>
          <Col md={6}>
            <h3>Audience Engagement</h3>
            <p>Foster interactions through comments, messages, and live sessions to build a loyal community.</p>
          </Col>
        </Row>
      </section>
      </Container>
        <h1>Social Media Optimization (SMO)</h1>
        <p>
          SEO is the process of improving the quality and quantity of website traffic to a website or a web page from search engines.
          SEO targets unpaid traffic rather than direct traffic or paid traffic.
        </p>

        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div></>
  );
};

export default SmoPage;