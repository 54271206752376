import React from 'react';
import './Home.css'; // Import custom CSS for styling
// import { Card } from 'react-bootstrap'; // Import Card component from Bootstrap
// import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Carousel, Card, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Digi1 from './assets/Digi1.jpg'; 
import Digi2 from './assets/Digi2.jpg';
import Digi3 from './assets/Digi3.jpg';

function Home() {
  return (
    <>
      <div>
      <Helmet>
        <title>Ultimate Brand Building Company in 2024</title>
        {/* You can also add other meta tags here */}
        <meta name="description" content="Looking for a top-notch digital service company to elevate your online presence? Look no further than Sparkdenz! Our team of tech-savvy experts is dedicated to providing cutting-edge digital solutions that will take your business to the next level. From website design and development to search engine optimization and social media marketing, we've got you covered. With Sparkdenz, you can expect professional service, quick turnaround times, and measurable results. Let us help spark up your online presence today! Trust us, you won't be disappointed. So go ahead, give us a shout and let's get started on transforming your digital footprint!" />
        <meta name="keywords" content="Ultimate Brand Building Company in 2024, Keyword2" />
      </Helmet>
      <h1>Your Page Content</h1>
    </div>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">WELcome</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="home">Home</Nav.Link>
            <Nav.Link href="service">Service</Nav.Link>
            <Nav.Link href="Body">Blog</Nav.Link>
            <Nav.Link href="About">About</Nav.Link>
         
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    <div>
      {/* Carousel Section */}
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Digi1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>PLAN</h3>
            {/* <p>Some text for the first slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Digi2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>DEVELOPE</h3>
            {/* <p>Some text for the second slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={Digi3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>ADVERTISE</h3>
            {/* <p>Some text for the third slide.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* Cards Section */}

      <Container className="my-5">
        <h1><b>OUR SERVICES</b></h1>
        <br></br>
        <Row>
          <Col md={3}>
            <Card className="mb-4">
              {/* <Card.Img variant="top" src="https://via.placeholder.com/300x200" /> */}
              <Card.Body>
                <Card.Title>SEO</Card.Title>
                <Card.Text>
                Discover top-notch <b>SEO </b>solutions tailored to meet your needs. Our expert team delivers exceptional results with personalized strategies. Contact us today!
                </Card.Text>
                <a href="top-global-seo-services-madurai-2024" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-4">
              {/* <Card.Img variant="top" src="https://via.placeholder.com/300x200" /> */}
              <Card.Body>
                <Card.Title>SEM</Card.Title>
                <Card.Text>
                Unlock premium <b>SEM</b> solutions customized for your needs. Our skilled team ensures outstanding results through tailored strategies. Get in touch today!
                </Card.Text>
                <a href="leading-sem-services-dindigul-2024" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-4">
              {/* <Card.Img variant="top" src="https://via.placeholder.com/300x200" /> */}
              <Card.Body>
                <Card.Title>SMO</Card.Title>
                <Card.Text>
                Elevate your experience with customized <b>SMO</b> solutions. Our expert team delivers outstanding results with personalized strategies. Connect with us today!
                </Card.Text>
                <a href="SmoPage" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="mb-4">
              {/* <Card.Img variant="top" src="https://via.placeholder.com/300x200" /> */}
              <Card.Body>
                <Card.Title>SMM
                </Card.Title>
                <Card.Text>
                Boost your success with our tailored <b>SMM</b> solutions. Our dedicated team delivers exceptional results through customized strategies. Reach out today to get started!
                </Card.Text>
                <a href="SmmPage" className="btn btn-primary">Learn More</a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    <footer className="bg-dark text-white py-4">
      <Container>
        <Row>
          <Col md={4}>
            <h5>About Us</h5>
            <ul>
              <li>
              <a href="SeoPage" className="text-white me-2">SEO</a>
               </li>
               <li>
              <a href="SemPage" className="text-white me-2">SEM</a>
               </li>
               <li>
              <a href="SmoPage" className="text-white me-2">SMO</a>
               </li>
               <li>
              <a href="SmmPage" className="text-white me-2">SMM</a>
               </li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Contact</h5>
            <p>Email: sivaprasadm203@gmail.com</p>
            <p>Phone: +123 456 7890</p>
          </Col>
          <Col md={4}>
            <h5>Follow Us</h5>
            <ul>
            <li><a href="#" className="text-white me-2">Facebook</a></li>
            <li><a href="#" className="text-white me-2">Instagram</a></li>
            <li><a href="#" className="text-white me-2">Twitter</a></li>
            <li><a href="#" className="text-white me-2">whatsapp</a></li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-8">
          <Col className="text-center">
            <p>&copy; 2024 Your Company Name. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
          
   </>
  );
}

export default Home;
